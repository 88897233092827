import request from '../utils/request'
/**
 * @param parameter
 * @returns {*}
 */
//组织架构树
export function getOrgTree(parameter) {
    return request({
        url: '/app/school-org-define/org-defines-trees',
        method: 'get',
        params: parameter
    })
}
//学生列表
export function getStudent(parameter) {
    var url = '/app/student'
    return request({
        url: url,
        method: 'get',
        params: parameter
    })
}
//教职工
export function getTeacher(parameter) {
    var url = '/app/teacher'
    return request({
        url: url,
        method: 'get',
        params: parameter
    })
  }