import request from '../utils/request'

const api = {
  meetingPhone: '/app/meeting-phone'
}
/**
 * @param parameter
 * @returns {*}
 */
// 获取用户信息
export function getUserInformation(parameter) {
  return request({
    url: '/app/meeting-phone/user-information',
    method: 'get',
    params: parameter
  })
}
// 首页参会记录
export function getHomeMeetingReserve(parameter) {
  return request({
    url: '/app/client-home/home-meeting-reserve',
    method: 'get',
    params: parameter
  })
}
// 我的参会记录
export function getMyAttendanceRecords(parameter) {
  return request({
    url: '/app/client-home/my-attendance-records',
    method: 'get',
    params: parameter
  })
}
// 我的直播列表
export function getLiveList(parameter) {
  return request({
    url: '/app/client-home/live-list',
    method: 'get',
    params: parameter
  })
}
// 上传文件
export function uploadFile(parameter) {
  return request({
    url: '/app/materials/upload-file',
    method: 'post',
    data: parameter
  })
}

// 申请会议
export function postMeetingReserve(parameter) {
  return request({
    url: '/app/client-home/meeting-reserve',
    method: 'post',
    data: parameter
  })
}
// 修改会议申请
export function putMeetingReserve(parameter) {
  return request({
    url: '/app/client-home/' + parameter.id + '/meeting-reserve',
    method: 'put',
    params: { id: parameter.id },
    data: parameter
  })
}
// 获取会议详情
export function getMeetingReserveDetail(parameter) {
  return request({
    url: '/app/client-home/' + parameter.id + '/meeting-reserve',
    method: 'get'
  })
}
// 获取人员列表
export function getPersonnelList(parameter) {
  return request({
    url: '/app/org-identity-user/org-identity-user-page',
    method: 'get',
    params: parameter
  })
}
// 修改用户头像
export function userInformation(parameter) {
  return request({
    url: '/app/user-information/'+parameter.id+'/user-avatar',
    method: 'put',
    data: parameter
  })
}
// 根据id获取用户头像
export function userAvatarByUserCode(parameter) {
  return request({
    url: '/app/user-information/user-avatar-by-user-code',
    method: 'get',
    params: parameter
  })
}
// 根据id获取用户类型
export function userDataTypeByUserCode(parameter) {
  return request({
    url: '/app/meeting-core/user-data-type-by-user-code',
    method: 'get',
    params: parameter
  })
}
// 获取翻译语种
export function getLangApi(parameter) {
  return request({
    url: '/app/client-home/phonetic-transcription-data-list',
    method: 'get',
    params: parameter
  })
}
// 获取通用背景图列表
export function getVirtualBackgroundCommands(parameter){
  return request({
     url:'/app/meeting-virtual-background-core/virtual-background-commands',
     method:'get',
     params:parameter
 })
}

// 直播详情信息
export function getLiveBroadcastDetails(parameter){
  return request({
    url:'/app/client-home/live-broadcast-details/'+parameter.LiveId,
    method:'get',
    params:parameter
 })
}