import request from '../utils/request'

const api = {
  meetingCore: '/app/meeting-core'
}

/**
 * 获取会议信息
 *
 * @param parameter 请求参数
 * @returns 返回请求结果
 */
export function getMeetingInformation(parameter) {
  return request({
    url: api.meetingCore + '/user-meeting-information/' + parameter.meetingId,
    method: 'get',
    // params:parameter
  })
}

/** 
 * 获取参会人员列表
 * @param {*} parameter 
 * @returns 
 */
export function getAttendeeList(parameter) {
  return request({
    url: api.meetingCore + '/attendee-list',
    method: 'get',
    params: parameter
  })
}

/**
 * 主持人设置参会人员会议相关状态
 * @param {*} parameter status 1语音状态 2摄像头状态 3是否主屏推流 statusValue 0禁用 1启用
 * @returns 
 */
export function setPersonnelStatus(parameter) {
  return request({
    url: api.meetingCore + '/user-seting-status',
    method: 'post',
    data: parameter
  })
}

/**
 * 结束会议
 * @returns 
 */
export function endMeeting(parameter) {
  return request({
    url: api.meetingCore + '/end-this-meeting/' + parameter.meetingId,
    method: 'post',
    data: parameter
  })
}

/**
 * 邀请参会人员
 */
export function InviteAttendees(parameter) {
  return request({
    url: api.meetingCore + '/inviting-attendees',
    method: 'post',
    data: parameter
  })
}
/**
 * 全体禁言
 * @returns 
 */
export function muteAll(parameter) {
  return request({
    url: api.meetingCore + '/all-mute/' + parameter.meetingId,
    method: 'post',
  })
}

/**
 * 获取会议列表
 * @param {*} parameter 
 * @returns 
 */
export function getMeetingFiles(parameter) {
  return request({
    url: api.meetingCore + '/meeting-file-list',
    method: 'get',
    params: parameter
  })
}
/**
 * 获取翻译列表
 * @param {*} parameter 
 * @returns 
 */
export function getMeetingTranslate(parameter) {
  return request({
    url: api.meetingCore + '/meeting-translate-list',
    method: 'get',
    params: parameter
  })
}

// 获取会议背景图列表
export function getVirtualBackground(parameter) {
  return request({
    url: '/app/meeting-virtual-background-core/virtual-background-by-meeting-id/' + parameter.meetingId,
    method: 'get',
    params: parameter
  })
}

// 提交语音转写文字
export function postMinutesRecevie(parameter) {
  return request({
    url: '/app/minutes-recevie/recevie',
    method: 'post',
    data: parameter
  })
}

// 获取全部翻译成员
export function getAllTranslateList(parameter) {
  return request({
    url: '/app/meeting-core/translate-list',
    method: 'get',
    params: parameter
  })
}

// 用户配置翻译语言
export function configUserMeetingTeanslation(parameter) {
  return request({
    url: '/app/minutes-recevie-core/config-user-meeting-translation',
    method: 'post',
    data: parameter
  })
}
// 会议纪要记录
export function meetingTranslationMinutesList(parameter) {
  return request({
    url: '/app/minutes-recevie-core/meeting-translation-minutes-list',
    method: 'get',
    params: parameter
  })
}

// 会议纪要语言版本
export function getMeetingTranslators(parameter) {
  return request({
    url: '/app/minutes-recevie-core/meeting-translators',
    method: 'get',
    params: parameter
  })
}
// 导出会议纪要
export function exportMinutesRecevieCore(parameter) {
  return request({
    url: '/app/minutes-recevie-core/export',
    method: 'post',
    data: parameter,
    responseType: 'blob'
  })
}
// 开始录制
export function beginMeetingVideoRecord(parameter) {
  return request({
    url: '/app/meeting-video-recording-info-core/begin-meeting-video-record',
    method: 'post',
    data: parameter
  })
}
// 结束录制
export function endMeetingVideoRecord(parameter) {
  return request({
    url: '/app/meeting-video-recording-info-core/end-meeting-video-record',
    method: 'post',
    data: parameter
  })
}
// 录指文件
export function userMeetingRecordingFiles(parameter) {
  return request({
    url: '/app/meeting-video-recording-info-core/user-meeting-recording-files',
    method: 'get',
    params: parameter
  })
}
// 结束录制
export function recordingScreenSharing(parameter) {
  return request({
    url: '/app/meeting-video-recording-info-core/set-up-recording-screen-sharing',
    method: 'post',
    data: parameter
  })
}
export function meetingVirtualBackgroundCore() {
  return request({
      url: '/app/meeting-virtual-background-core/setting',
      method: 'get'
  })
}

export function meetingChatList(parameter) {
  console.log(parameter,'parameter')
  return request({
    url: '/app/meeting-core/meeting-speech-log-infos-by-meeting-id',
    method: 'get',
    params: parameter
  })
}