import ppt from "@/assets/images/ppt.png";
import word from "@/assets/images/word.png";
import cdr from "@/assets/images/cdr.png";
import excel from "@/assets/images/excel.png";
import exe from "@/assets/images/exe.png";
import gif from "@/assets/images/gif.png";
import pdf from "@/assets/images/pdf.png";
import rar from "@/assets/images/rar.png";
import tif from "@/assets/images/tif.png";
import txt from "@/assets/images/txt.png";
import img from "@/assets/images/img.png";
import nullImg from "@/assets/images/null.png";
const formatImageUrl = (url) => {
  if (url && url != null) {
    // let baseUrl = process.env.VUE_APP_BASE_API;
    let baseUrl = window._config.apiBaseUrl;

    // let urlObj = new URL(baseUrl);
    //    let host = window.location.host // let protocolAndHost =  urlObj.protocol + "//" + urlObj.hostname + (urlObj.port ? ':' + urlObj.port : '');
    // let baseUrl =  window.location.protocol + "//" + window.location.hostname;
    if (baseUrl) {
      new_url = baseUrl + "/" + url;
    } else {
      new_url = window.location.origin + "/" + url;
    }
    return new_url;
  } else {
    return url;
  }
};
const judgeFileType = (fileName) => {
  if (fileName.indexOf(".ppt") != -1) {
    return ppt;
  }
  if (fileName.indexOf(".doc") != -1) {
    return word;
  }
  if (fileName.indexOf(".cdr") != -1) {
    return cdr;
  }
  if (fileName.indexOf(".xls") != -1) {
    return excel;
  }
  if (fileName.indexOf(".exe") != -1) {
    return exe;
  }
  if (fileName.indexOf(".gif") != -1) {
    return gif;
  }
  if (fileName.indexOf(".pdf") != -1) {
    return pdf;
  }
  if (fileName.indexOf(".rar") != -1 || fileName.indexOf(".zip") != -1) {
    return rar;
  }
  if (
    fileName.indexOf(".png") != -1 ||
    fileName.indexOf(".jpg") != -1 ||
    fileName.indexOf(".jpeg") != -1 ||
    fileName.indexOf(".mp4") != -1
  ) {
    return img;
  }
  if (fileName.indexOf(".tif") != -1) {
    return tif;
  }
  if (fileName.indexOf(".txt") != -1) {
    return txt;
  }
  return nullImg;
};

const isUndefined = (value) => {
  return typeof value === "undefined";
};
const addSuffix = (value, suffix = "px") => {
  if (this.isNumber(value) || this.isStringNumber(value)) {
    return value + suffix;
  }
  return value;
};
export default { formatImageUrl, judgeFileType, isUndefined, addSuffix };
